/*
 * Advisories
 *
 */
.ksu-advisory {
  z-index: 10;
  background-color: #eee;
}
.ksu-advisory > div {
  color: #333;
  max-width: 50em;
}
.ksu-advisory h3 {
  margin: 0 0 0.3em;
  padding: 0;
  font-size: 1.8rem;
  font-weight: bold;
}
.ksu-advisory h3 a:link,
.ksu-advisory h3 a:visited,
.ksu-advisory h3 a:active {
  color: #ba1623;
}
.ksu-advisory img {
  display: block;
  margin: 15px 10px 0 auto;
}
.ksu-advisory ul {
  margin: 1em 0 1em 2em;
  list-style: disc;
}

.ksu-adv-summary {
  margin: 0 0 2em;
}

.ksu-adv-inactive {
  color: #333;
}

.ksu-adv-active {
  color: #ba1623;
}
